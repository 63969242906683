<script lang="ts" setup>
import { computed } from 'vue';
import { useStore } from '../../store';

const store = useStore();

const isDataLoaded = computed(() => {
  return store.state.isDataLoaded;
});

function openSubscribePopup() {
  store.dispatch('setIsSubscribeOpen', true);
}

function openDescriptionPopup() {
  store.dispatch('setIsDescriptionOpen', true);
}

function openMenuPopup() {
  store.dispatch('setIsMenuOpen', true);
}
</script>

<script lang="ts">
export default {
  name: 'EpisodeToolbar',
};
</script>

<template>
  <div class="episode-toolbar">
    <button
      class="episode-toolbar__subscribe"
      :disabled="!isDataLoaded"
      aria-label="Подписаться"
      @click="openSubscribePopup"
    >
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
        <g clip-path="url(#clip0_16395_18348)">
          <path
            d="M7.49999 9.16667C9.34094 9.16667 10.8333 7.67428 10.8333 5.83333C10.8333 3.99238 9.34094 2.5 7.49999 2.5C5.65904 2.5 4.16666 3.99238 4.16666 5.83333C4.16666 7.67428 5.65904 9.16667 7.49999 9.16667Z"
            stroke="var(--primary-color)"
            stroke-width="1.3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M2.5 17.5V15.8333C2.5 14.9493 2.85119 14.1014 3.47631 13.4763C4.10143 12.8512 4.94928 12.5 5.83333 12.5H9.16667C10.0507 12.5 10.8986 12.8512 11.5237 13.4763C12.1488 14.1014 12.5 14.9493 12.5 15.8333V17.5"
            stroke="var(--primary-color)"
            stroke-width="1.3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M15.8333 6.66666V11.6667M13.3333 9.16666H18.3333H13.3333Z"
            stroke="var(--primary-color)"
            stroke-width="1.3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_16395_18348">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>

      <span class="text">Подписаться</span>
    </button>

    <button
      class="episode-toolbar__description"
      :disabled="!isDataLoaded"
      @click="openDescriptionPopup"
    >
      <div class="icon">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path
            d="M15.9844 4.26562H7.39063C6.44139 4.26562 5.67188 5.03514 5.67188 5.98438V18.0156C5.67188 18.9649 6.44139 19.7344 7.39063 19.7344H15.9844C16.9336 19.7344 17.7031 18.9649 17.7031 18.0156V5.98438C17.7031 5.03514 16.9336 4.26562 15.9844 4.26562Z"
            stroke="var(--primary-color)"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M9.10922 7.70312H14.2655"
            stroke="var(--primary-color)"
            stroke-width="1.3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M9.10922 11.1406H14.2655"
            stroke="var(--primary-color)"
            stroke-width="1.3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M9.10922 14.5781H12.5467"
            stroke="var(--primary-color)"
            stroke-width="1.3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <span class="text">Описание выпуска</span>
    </button>

    <m-button-rect
      class="episode-toolbar__menu"
      :disabled="!isDataLoaded"
      label="Открыть меню"
      @click="openMenuPopup"
    >
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path
          d="M10.3047 6.34375C11.1461 6.34375 11.8281 5.66168 11.8281 4.82031C11.8281 3.97894 11.1461 3.29688 10.3047 3.29688C9.46332 3.29688 8.78125 3.97894 8.78125 4.82031C8.78125 5.66168 9.46332 6.34375 10.3047 6.34375Z"
          fill="var(--primary-color)"
        />
        <path
          d="M10.3047 11.6758C11.1461 11.6758 11.8281 10.9937 11.8281 10.1523C11.8281 9.31097 11.1461 8.62891 10.3047 8.62891C9.46332 8.62891 8.78125 9.31097 8.78125 10.1523C8.78125 10.9937 9.46332 11.6758 10.3047 11.6758Z"
          fill="var(--primary-color)"
        />
        <path
          d="M10.3047 17.0078C11.1461 17.0078 11.8281 16.3257 11.8281 15.4844C11.8281 14.643 11.1461 13.9609 10.3047 13.9609C9.46332 13.9609 8.78125 14.643 8.78125 15.4844C8.78125 16.3257 9.46332 17.0078 10.3047 17.0078Z"
          fill="var(--primary-color)"
        />
      </svg>
    </m-button-rect>
  </div>
</template>

<style lang="scss">
@import './episode-toolbar';
</style>
