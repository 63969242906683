<script lang="ts" setup>
import { computed } from 'vue';
import { useStore } from '../../store';

const props = defineProps({
  podcastTitle: {
    type: String,
  },
  podcastSlug: {
    type: String,
  },
  episodeTitle: {
    type: String,
  },
  episodeNumber: {
    type: Number,
  },
});

const store = useStore();

const isDataLoaded = computed(() => {
  return store.state.isDataLoaded;
});
</script>

<script lang="ts">
export default {
  name: 'EpisodeTitle',
};
</script>

<template>
  <div class="episode-title">
    <MSkeleton
      v-if="!isDataLoaded"
      class="episode-title__title"
      width="200"
      height="18"
    />
    <h1 v-else class="episode-title__title">
      <a
        :href="`https://${props.podcastSlug}.mave.digital/ep-${props.episodeNumber}?utm_source=embed&utm_medium=title`"
        target="_blank"
      >
        {{ props.episodeTitle }}
      </a>
    </h1>

    <MSkeleton v-if="!isDataLoaded" width="165" height="18" />
    <a
      v-else
      class="episode-title__podcast-link"
      :href="`https://${props.podcastSlug}.mave.digital?utm_source=embed`"
      target="_blank"
    >
      {{ props.podcastTitle }}
      <svg width="14" height="15" viewBox="0 0 14 15" fill="none">
        <g clip-path="url(#clip0_16395_18268)">
          <path
            d="M5.25 4L8.75 7.5L5.25 11"
            stroke="var(--primary-color)"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_16395_18268">
            <rect
              width="14"
              height="14"
              fill="white"
              transform="translate(0 0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    </a>
  </div>
</template>

<style lang="scss">
@import './episode-title';
</style>
