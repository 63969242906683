<script setup lang="ts">
import { computed } from 'vue';
import { useStore } from '../../store';

const store = useStore();

const podcast = computed(() => {
  return store.state.podcast;
});
const episode = computed(() => {
  return store.state.episode;
});

function openDescription() {
  store.dispatch('setIsMenuOpen', false);
  store.dispatch('setIsDescriptionOpen', true);
}

function openSharing() {
  store.dispatch('setIsMenuOpen', false);
  store.dispatch('setIsSharingOpen', true);
}
</script>

<script lang="ts">
export default {
  name: 'PopupMenu',
};
</script>

<template>
  <nav class="menu">
    <a
      class="menu__item menu-item description"
      href="#"
      role="button"
      @click.prevent="openDescription"
    >
      <div class="menu-item__icon-wrapper">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
          <path
            d="M12.1875 2.8125H5.3125C4.55311 2.8125 3.9375 3.42811 3.9375 4.1875V13.8125C3.9375 14.5719 4.55311 15.1875 5.3125 15.1875H12.1875C12.9469 15.1875 13.5625 14.5719 13.5625 13.8125V4.1875C13.5625 3.42811 12.9469 2.8125 12.1875 2.8125Z"
            stroke="var(--primary-color)"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M6.68738 5.5625H10.8124"
            stroke="var(--primary-color)"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M6.68738 8.3125H10.8124"
            stroke="var(--primary-color)"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M6.68738 11.0625H9.43738"
            stroke="var(--primary-color)"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <span class="menu-item__label">Описание выпуска</span>
      <svg
        class="menu-item__arrow-icon"
        width="14"
        height="15"
        viewBox="0 0 14 15"
        fill="none"
      >
        <g clip-path="url(#clip0_16395_18268)">
          <path
            d="M5.25 4L8.75 7.5L5.25 11"
            stroke="var(--primary-color)"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_16395_18268">
            <rect
              width="14"
              height="14"
              fill="white"
              transform="translate(0 0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    </a>
    <a
      class="menu__item menu-item"
      :href="`https://${podcast.slug}.mave.digital/ep-${episode.code}?utm_source=embed`"
      target="_blank"
    >
      <div class="menu-item__icon-wrapper">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
          <path
            d="M9 15.1875C12.4173 15.1875 15.1875 12.4173 15.1875 9C15.1875 5.58274 12.4173 2.8125 9 2.8125C5.58274 2.8125 2.8125 5.58274 2.8125 9C2.8125 12.4173 5.58274 15.1875 9 15.1875Z"
            stroke="var(--primary-color)"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M3.2251 6.9375H14.7751"
            stroke="var(--primary-color)"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M3.2251 11.0625H14.7751"
            stroke="var(--primary-color)"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M8.65627 2.8125C7.49806 4.66848 6.88403 6.81228 6.88403 9C6.88403 11.1877 7.49806 13.3315 8.65627 15.1875"
            stroke="var(--primary-color)"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M9.34387 2.8125C10.5021 4.66848 11.1161 6.81228 11.1161 9C11.1161 11.1877 10.5021 13.3315 9.34387 15.1875"
            stroke="var(--primary-color)"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <span class="menu-item__label">Сайт выпуска</span>
      <svg
        class="menu-item__arrow-icon"
        width="14"
        height="15"
        viewBox="0 0 14 15"
        fill="none"
      >
        <g clip-path="url(#clip0_16395_18268)">
          <path
            d="M5.25 4L8.75 7.5L5.25 11"
            stroke="var(--primary-color)"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_16395_18268">
            <rect
              width="14"
              height="14"
              fill="white"
              transform="translate(0 0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    </a>
    <a
      class="menu__item menu-item"
      href="#"
      role="button"
      @click.prevent="openSharing"
    >
      <div class="menu-item__icon-wrapper">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
          <g clip-path="url(#clip0_16690_15501)">
            <path
              d="M4.875 11.0625C6.01409 11.0625 6.9375 10.1391 6.9375 9C6.9375 7.86091 6.01409 6.9375 4.875 6.9375C3.73591 6.9375 2.8125 7.86091 2.8125 9C2.8125 10.1391 3.73591 11.0625 4.875 11.0625Z"
              stroke="var(--primary-color)"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M13.1252 6.9375C14.2643 6.9375 15.1877 6.01409 15.1877 4.875C15.1877 3.73591 14.2643 2.8125 13.1252 2.8125C11.9862 2.8125 11.0627 3.73591 11.0627 4.875C11.0627 6.01409 11.9862 6.9375 13.1252 6.9375Z"
              stroke="var(--primary-color)"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M13.1252 15.1875C14.2643 15.1875 15.1877 14.2641 15.1877 13.125C15.1877 11.9859 14.2643 11.0625 13.1252 11.0625C11.9862 11.0625 11.0627 11.9859 11.0627 13.125C11.0627 14.2641 11.9862 15.1875 13.1252 15.1875Z"
              stroke="var(--primary-color)"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M6.73132 8.1063L11.2688 5.7688"
              stroke="var(--primary-color)"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M6.73132 9.8938L11.2688 12.2313"
              stroke="var(--primary-color)"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_16690_15501">
              <rect width="18" height="18" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
      <span class="menu-item__label">Поделиться</span>
      <svg
        class="menu-item__arrow-icon"
        width="14"
        height="15"
        viewBox="0 0 14 15"
        fill="none"
      >
        <g clip-path="url(#clip0_16395_18268)">
          <path
            d="M5.25 4L8.75 7.5L5.25 11"
            stroke="var(--primary-color)"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_16395_18268">
            <rect
              width="14"
              height="14"
              fill="white"
              transform="translate(0 0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    </a>
  </nav>
</template>

<style lang="scss">
@import './menu';
</style>
