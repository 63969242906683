<script lang="ts" setup>
const props = defineProps({
  color: {
    type: String,
    required: true,
  },
});
</script>

<script lang="ts">
export default {
  name: 'IconPlayerForward',
};
</script>

<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.44541 6.60505C2.75944 5.32851 3.57211 4.17903 4.79948 3.47041C5.94182 2.81088 7.23482 2.65351 8.42622 2.92991C9.70129 3.22572 10.8557 4.01727 11.5797 5.2215L10.2405 5.23082C9.88151 5.23331 9.59253 5.52635 9.59503 5.88532C9.59753 6.2443 9.89056 6.53328 10.2495 6.53079L12.9242 6.51217L13.4813 6.5083L13.5627 5.95713L13.9533 3.31111C14.0058 2.95598 13.7604 2.62558 13.4053 2.57314C13.0501 2.52071 12.7197 2.7661 12.6673 3.12123L12.5007 4.24979C11.5829 2.90129 10.2154 2.01046 8.72002 1.66355C7.22031 1.31562 5.58836 1.51384 4.14948 2.34457C2.60299 3.23744 1.57814 4.68842 1.18304 6.29451C0.808646 7.81643 0.998715 9.48155 1.84457 10.9466C2.02407 11.2575 2.4216 11.364 2.73249 11.1845C3.04338 11.005 3.1499 10.6075 2.97041 10.2966C2.29893 9.13359 2.14794 7.81427 2.44541 6.60505Z"
      :fill="props.color"
    />
    <path
      d="M6.64256 15.1406C8.12352 15.1406 9.11229 14.3628 9.11229 13.2114V13.2026C9.11229 12.3413 8.49705 11.7964 7.55223 11.7085V11.6821C8.2949 11.5283 8.87498 11.0142 8.87498 10.2231V10.2144C8.87498 9.20361 7.99607 8.51807 6.63377 8.51807C5.30223 8.51807 4.41014 9.25635 4.31785 10.3901L4.31346 10.4429H5.53514L5.53953 10.4033C5.59227 9.87598 6.00975 9.5376 6.63377 9.5376C7.25779 9.5376 7.62254 9.86279 7.62254 10.3901V10.3989C7.62254 10.9131 7.19188 11.2646 6.52391 11.2646H5.81639V12.2095H6.54148C7.31053 12.2095 7.76316 12.5435 7.76316 13.1499V13.1587C7.76316 13.6948 7.31932 14.0728 6.64256 14.0728C5.95701 14.0728 5.50438 13.7212 5.44725 13.229L5.44285 13.1807H4.17283L4.17723 13.2378C4.26512 14.3716 5.20994 15.1406 6.64256 15.1406ZM12.4194 15.1626C13.9883 15.1626 14.9375 13.8794 14.9375 11.8315V11.8228C14.9375 9.77051 13.9883 8.49609 12.4194 8.49609C10.855 8.49609 9.90137 9.77051 9.90137 11.8228V11.8315C9.90137 13.8794 10.855 15.1626 12.4194 15.1626ZM12.4194 14.1123C11.6724 14.1123 11.2461 13.2642 11.2461 11.8315V11.8228C11.2461 10.3857 11.6724 9.54639 12.4194 9.54639C13.1709 9.54639 13.5972 10.3857 13.5972 11.8228V11.8315C13.5972 13.2642 13.1709 14.1123 12.4194 14.1123Z"
      :fill="props.color"
    />
  </svg>
</template>
