<script setup lang="ts">
import { provide, ref } from 'vue';
import { parseUrlString } from './utils';
import Player from '@modules/Player.vue';
// import Error from '@modules/error/Index.vue';

const playerParams = parseUrlString(window.location.search);
const playerParamsProp = ref(playerParams);

provide('storageUrl', import.meta.env.VUE_STORAGE_URL);
provide('apiBaseUrl', import.meta.env.VUE_API_BASE_URL);

setColor(playerParams.color);

function setColor(color: string | null): void {
  if (!color) {
    document.documentElement.style.setProperty('--color-rgb', '95,128,245');
  }

  document.documentElement.style.setProperty('--color-rgb', color);
}
</script>

<script lang="ts">
export default {
  name: 'App',
};
</script>

<template>
  <Suspense>
    <div class="app-inner">
      <Player :params="playerParamsProp" />
    </div>
  </Suspense>
</template>
