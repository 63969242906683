import { UrlParams } from '../types';

export function parseUrlString(urlString: string): UrlParams {
  const params: URLSearchParams = new URLSearchParams(urlString);
  const paramsObject = createParamsObject(params);
  const mappedParams = mapParams(paramsObject);

  return mappedParams;
}

function createParamsObject(params: URLSearchParams): UrlParams {
  const paramsObject: UrlParams = {
    podcast: params.get('podcast'),
    episode: Number(params.get('episode')),
    mute: params.has('mute') ? getBooleanParamValue(params.get('mute')) : true,
    download: params.has('download')
      ? getBooleanParamValue(params.get('download'))
      : true,
    date: params.has('date') ? getBooleanParamValue(params.get('date')) : true,
    color: params.has('color') ? params.get('color') : 'rgb(95,128,245)',
  };

  return paramsObject;
}

function getBooleanParamValue(param: any): boolean {
  if (Number(param) === 1) {
    return true;
  }

  return false;
}

function mapParams(params: UrlParams): UrlParams {
  return {
    ...params,
    color: extractColorFromRgbFormat(params.color) || params.color,
  };
}

function extractColorFromRgbFormat(rgbString: string | null): string | null {
  if (!rgbString) {
    return '95,128,245';
  }

  const colorRegEx = /\((.*?)\)/;
  const extractedColor = colorRegEx.exec(rgbString);
  if (extractedColor) {
    return extractedColor[1];
  }

  return '95,128,245';
}
