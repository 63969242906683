<script lang="ts" setup>
const props = defineProps({
  label: {
    type: String,
  },
});
</script>

<script lang="ts">
export default {
  name: 'MButtonRect',
};
</script>

<template>
  <button class="button-rect" :aria-label="props.label">
    <slot />
  </button>
</template>

<style lang="scss">
@import './button-rect';
</style>
