<script lang="ts" setup>
import PlayerBackward from '../icons/PlayerBackward.vue';
import PlayerForward from '../icons/PlayerForward.vue';
import PlayerPlay from '../icons/PlayerPlay.vue';
import PlayerPause from '../icons/PlayerPause.vue';
import PlayerMute from '../icons/PlayerMute.vue';
import PlayerUnmute from '../icons/PlayerUnmute.vue';
</script>

<script lang="ts">
/* @ts-ignore */
import { Howl } from 'howler';
import tippy, { followCursor, Tippy } from 'tippy.js';
import { secondsToHMS } from '../../utils/datetimeFormatter';

export default {
  name: 'MPlayer',
  inject: ['storageUrl', 'apiBaseUrl'],
  data() {
    return {
      isAudioDataLoaded: false as boolean,
      isHowlerInit: false as boolean,

      howler: {} as Howl,
      trackDuration: 0 as number,
      trackCurrentDuration: 0 as number,
      isPaused: true as boolean,
      isMuted: false as boolean,
      currentSpeed: 1 as number,

      tooltipElement: {} as Tippy,
      progressElementLeftOffset: 0 as number,
      progressElementWidth: 0 as number,
    };
  },
  computed: {
    params() {
      return this.$store.state.playerParams;
    },
    podcast() {
      return this.$store.state.podcast;
    },
    episode() {
      return this.$store.state.episode;
    },
    audio() {
      return this.$store.state.episode.audio;
    },
    trackCurrentDurationOutput() {
      return secondsToHMS(this.trackCurrentDuration);
    },
  },
  watch: {
    audio(value) {
      if (value) {
        this.isAudioDataLoaded = true;
        this.setMediaSession();
      }
    },
  },
  mounted() {
    this.progressElementLeftOffset = (
      this.$refs.playerTrack as Element
    ).getBoundingClientRect().left;
    this.progressElementWidth = (this.$refs.playerTrack as Element).clientWidth;
  },
  methods: {
    initHowler() {
      this.howler = new Howl({
        src: `${this.apiBaseUrl}${this.audio}`,
        html5: true,
        volume: 1,
        onload: () => {
          this.isHowlerInit = true;
          this.trackDuration = this.howler.duration();
          this.setMediaSession();
        },
        onplay: () => {
          this.setMediaSession();
          navigator.mediaSession.playbackState = 'playing';
          requestAnimationFrame(this.step.bind(this));
        },
        onend: () => {
          this.stop();
        },
        onpause: () => {
          navigator.mediaSession.playbackState = 'paused';
        },
        onseek: () => {
          requestAnimationFrame(this.step.bind(this));
        },
      });

      this.currentSpeed = 1;

      /* @ts-ignore */
      this.tooltipElement = tippy(this.$refs.playerTrack as Element, {
        touch: false,
        arrow: false,
        theme: 'm-tippy',
        placement: 'top',
        hideOnClick: false,
        plugins: [followCursor],
        followCursor: 'horizontal',
        offset: [0, 4],
      });
    },
    async play() {
      if (!this.isHowlerInit) {
        this.initHowler();
      }

      this.howler.play();
      this.isPaused = false;
    },
    pause() {
      this.howler.pause();
      this.isPaused = true;
    },
    playPause() {
      if (this.isPaused) {
        this.play();
      } else {
        this.pause();
      }
    },
    stop() {
      this.howler.stop();
      this.isPaused = true;
      navigator.mediaSession.playbackState = 'paused';
    },
    seek(event: MouseEvent) {
      if (!this.isHowlerInit) {
        return;
      }

      const clickPosition = event.clientX - this.progressElementLeftOffset;

      const per = clickPosition / this.progressElementWidth;

      if (this.howler.playing()) {
        this.howler.pause();
        this.howler.seek(this.howler.duration() * per);
        this.howler.play();
      } else {
        this.howler.seek(this.howler.duration() * per);
      }
    },
    showTimestamp(event: MouseEvent) {
      if (!this.isHowlerInit) {
        return;
      }

      const mouseoverPoint: number =
        event.clientX - this.progressElementLeftOffset;

      if (mouseoverPoint < 0) {
        return;
      }

      const mouseoverPointInPercent: number =
        (mouseoverPoint / this.progressElementWidth) * 100;
      const mouseoverPointInSeconds: number =
        (mouseoverPointInPercent / 100) * this.trackDuration;
      const mouseoverPointInHMS: string = secondsToHMS(mouseoverPointInSeconds);

      /* @ts-ignore */
      this.tooltipElement.setContent(mouseoverPointInHMS);
    },
    step() {
      const progressElement: HTMLElement | null = document.querySelector(
        '.track .track__progress'
      );

      // Determine our current seek position.
      const seek = this.howler.seek() || 0;
      this.trackCurrentDuration = seek;
      if (progressElement) {
        progressElement.style.width =
          ((seek / this.howler.duration()) * 100 || 0) + '%';
      }

      // If the sound is still playing, continue stepping.
      if (this.howler.playing()) {
        requestAnimationFrame(this.step.bind(this));
      }
    },
    speedUpTrack() {
      if (this.currentSpeed === 2) {
        this.currentSpeed = 1;
      } else {
        this.currentSpeed = this.currentSpeed + 0.25;
      }
      this.howler.rate(this.currentSpeed);
    },
    toggleMuteness() {
      if (this.isMuted) {
        this.howler.mute(false);
        this.isMuted = false;
      } else {
        this.howler.mute(true);
        this.isMuted = true;
      }
    },
    skipBackward(seekDuration: number) {
      const seek = this.howler.seek() || 0;
      this.howler.seek(seek - seekDuration);
    },
    skipForward(seekDuration: number) {
      const seek = this.howler.seek() || 0;
      this.howler.seek(seek + seekDuration);
    },
    setMediaSession() {
      if ('mediaSession' in navigator) {
        const _this = this;

        navigator.mediaSession.metadata = new MediaMetadata({
          title: this.episode.title,
          artist: this.podcast.author,
          album: this.podcast.title,
          artwork: [
            {
              src: `${this.storageUrl}${this.episode.image}`,
            },
          ],
        });

        navigator.mediaSession.setActionHandler('play', _this.play);
        navigator.mediaSession.setActionHandler('pause', _this.pause);
        navigator.mediaSession.setActionHandler('stop', _this.stop);
        navigator.mediaSession.setActionHandler('seekbackward', () => {
          _this.skipBackward(10);
        });
        navigator.mediaSession.setActionHandler('seekforward', () => {
          _this.skipForward(10);
        });
      }
    },
  },
};
</script>

<template>
  <div class="m-player">
    <div class="m-player__controls">
      <button
        class="m-player__control play"
        :disabled="!isAudioDataLoaded"
        :aria-label="
          isPaused ? 'Воспроизвести выпуск' : 'Поставить выпуск на паузу'
        "
        @click="playPause()"
      >
        <PlayerPlay v-if="isPaused" class="play-icon" color="#FFFFFF" />
        <PlayerPause v-else color="#FFFFFF" />
      </button>
      <button
        class="m-player__control backward"
        :disabled="!isHowlerInit || trackCurrentDuration < 10"
        aria-label="Назад на 10 секунд"
        @click="skipBackward(10)"
      >
        <PlayerBackward color="var(--primary-color)" />
      </button>
      <button
        class="m-player__control forward"
        :disabled="!isHowlerInit"
        aria-label="Вперед на 30 секунд"
        @click="skipForward(30)"
      >
        <PlayerForward color="var(--primary-color)" />
      </button>
    </div>

    <div class="m-player__track-inner">
      <div
        ref="playerTrack"
        class="m-player__track"
        :class="{ disabled: !isHowlerInit }"
        @click.prevent="seek($event)"
        @mousemove="showTimestamp($event)"
      >
        <div class="track">
          <div class="track__progress">
            <div class="track__thumb"></div>
          </div>
        </div>
      </div>
      <span class="m-player__time">{{ trackCurrentDurationOutput }}</span>
      <button
        v-if="params.mute"
        class="m-player__mute-control"
        :disabled="!isHowlerInit"
        :aria-label="isMuted ? 'Включить звук' : 'Выключить звук'"
        @click="toggleMuteness()"
      >
        <PlayerMute v-if="!isMuted" color="var(--primary-color)" />
        <PlayerUnmute v-else color="var(--primary-color)" />
      </button>
      <button
        class="m-player__speed-control"
        :disabled="!isHowlerInit"
        aria-label="Ускорить воспроизведение"
        @click="speedUpTrack()"
      >
        {{ currentSpeed }}×
      </button>
    </div>
  </div>
</template>

<style lang="scss">
@import './_player.scss';
</style>
