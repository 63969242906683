<script lang="ts" setup>
import { computed } from 'vue';

const props = defineProps({
  type: {
    type: String,
    required: false,
    default: 'rect',
    validator: (value: string) => {
      return ['text', 'rect', 'circle', 'rounded', 'round'].includes(value);
    },
  },
  animation: {
    type: String,
    required: false,
    default: 'wave',
    validator: (value: string) => {
      return ['wave', 'fade', 'blink'].includes(value);
    },
  },
  size: {
    type: String || null,
    required: false,
    default: null,
  },
  width: {
    type: String || null,
    required: false,
    default: null,
  },
  height: {
    type: String || null,
    required: false,
    default: null,
  },
});

const classes = computed(() => {
  const classes = ['m-skeleton'];
  classes.push(`m-skeleton--${props.type}`);
  classes.push('m-skeleton--anim');
  classes.push(`m-skeleton--anim-${props.animation}`);
  return classes;
});

const styles = computed(() => {
  if (!props.size && !props.width && !props.height) {
    return {};
  } else {
    return {
      width: `${getDimension(Number(props.width), Number(props.size))}px`,
      height: `${getDimension(Number(props.height), Number(props.size))}px`,
    };
  }
});

function getDimension(
  dimension: number | null,
  size: number | null
): number | null {
  if (dimension) {
    return dimension;
  } else if (!dimension && size) {
    return size;
  } else {
    return null;
  }
}
</script>

<script lang="ts">
export default {
  name: 'MSkeleton',
};
</script>

<template>
  <div :class="classes" :style="styles" />
</template>

<style lang="scss" scoped>
@import './skeleton';
</style>
