<script lang="ts" setup>
const props = defineProps({
  color: {
    type: String,
    required: true,
  },
});
</script>

<script lang="ts">
export default {
  name: 'IconPlayerPlay',
};
</script>

<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M14.7327 9.06338C15.399 9.44539 15.4037 10.4048 14.7413 10.7934L5.88101 15.9915C5.21437 16.3826 4.375 15.9018 4.375 15.1289V4.85101C4.375 4.08256 5.20572 3.60127 5.87238 3.98349L14.7327 9.06338Z"
      :fill="props.color"
    />
  </svg>
</template>
