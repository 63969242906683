<script lang="ts" setup>
const props = defineProps({
  color: {
    type: String,
    required: true,
  },
});
</script>

<script lang="ts">
export default {
  name: 'IconPlayerBackward',
};
</script>

<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.5381 6.60505C14.2241 5.32851 13.4114 4.17903 12.184 3.47041C11.0417 2.81088 9.7487 2.65351 8.5573 2.92991C7.28223 3.22572 6.12782 4.01727 5.40378 5.2215L6.74306 5.23082C7.10203 5.23331 7.39102 5.52635 7.38852 5.88532C7.38602 6.2443 7.09299 6.53328 6.73401 6.53079L4.05937 6.51217L3.50225 6.5083L3.42087 5.95713L3.0302 3.31111C2.97777 2.95598 3.22316 2.62558 3.57829 2.57314C3.93342 2.52071 4.26382 2.7661 4.31626 3.12123L4.48288 4.24976C5.40062 2.90128 6.76818 2.01046 8.26351 1.66355C9.76321 1.31562 11.3952 1.51384 12.834 2.34457C14.3805 3.23744 15.4054 4.68842 15.8005 6.29451C16.1749 7.81643 15.9848 9.48155 15.1389 10.9466C14.9595 11.2575 14.5619 11.364 14.251 11.1845C13.9401 11.005 13.8336 10.6075 14.0131 10.2966C14.6846 9.13359 14.8356 7.81427 14.5381 6.60505Z"
      :fill="props.color"
    />
    <path
      d="M4.69287 15H6.02002V8.65869H4.69727L3.05811 9.79688V10.9922L4.61377 9.91113H4.69287V15ZM9.91604 15.1626C11.4849 15.1626 12.4341 13.8794 12.4341 11.8315V11.8228C12.4341 9.77051 11.4849 8.49609 9.91604 8.49609C8.35158 8.49609 7.39797 9.77051 7.39797 11.8228V11.8315C7.39797 13.8794 8.35158 15.1626 9.91604 15.1626ZM9.91604 14.1123C9.16896 14.1123 8.7427 13.2642 8.7427 11.8315V11.8228C8.7427 10.3857 9.16896 9.54639 9.91604 9.54639C10.6675 9.54639 11.0938 10.3857 11.0938 11.8228V11.8315C11.0938 13.2642 10.6675 14.1123 9.91604 14.1123Z"
      :fill="props.color"
    />
  </svg>
</template>
