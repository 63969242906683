<script setup lang="ts">
import { computed } from 'vue';
import { useStore } from '../../store';

const props = defineProps({
  position: {
    type: String,
    default: 'bottom-right',
    validation: (val: string) => ['top-right', 'bottom-right'].includes(val),
  },
});

const store = useStore();

const classes = computed(() => {
  const classes = [];
  classes.push(props.position);

  return classes;
});

const podcastSlug = computed(() => {
  return store.state.podcast.slug;
});
</script>

<script lang="ts">
export default {
  name: 'LogoLink',
};
</script>

<template>
  <a
    class="logo"
    :class="classes"
    :href="`https://mave.digital/creators?utm_source=embed&utm_medium=logo&utm_campaign=${podcastSlug}`"
    target="_blank"
    aria-label="Перейти на сайт мэйв"
  >
    <svg width="42" height="9" viewBox="0 0 42 9" fill="none">
      <path
        d="M21.8847 0.0555915V1.22624C21.2873 0.636835 20.529 0.237218 19.7052 0.0776429C18.8813 -0.0819323 18.0286 0.00565958 17.2544 0.329403C16.4802 0.653145 15.8189 1.19858 15.3539 1.89713C14.8888 2.59567 14.6407 3.41613 14.6407 4.25532C14.6407 5.09451 14.8888 5.91496 15.3539 6.61351C15.8189 7.31205 16.4802 7.85749 17.2544 8.18123C18.0286 8.50497 18.8813 8.59257 19.7052 8.43299C20.529 8.27342 21.2873 7.8738 21.8847 7.28439V8.45722H23.2799V0.0555915H21.8847ZM18.9047 7.13834C18.3342 7.13833 17.7765 6.96913 17.3021 6.65212C16.8278 6.33512 16.4581 5.88455 16.2399 5.35742C16.0217 4.83029 15.9647 4.25028 16.0761 3.69076C16.1876 3.13124 16.4625 2.61735 16.8661 2.21408C17.2696 1.81082 17.7838 1.53631 18.3434 1.42527C18.903 1.31423 19.4829 1.37166 20.0099 1.59028C20.5369 1.80891 20.9871 2.17891 21.3038 2.65349C21.6204 3.12806 21.7892 3.68589 21.7888 4.25641C21.7882 5.02094 21.4841 5.75397 20.9433 6.29437C20.4025 6.83478 19.6692 7.13834 18.9047 7.13834Z"
      />
      <path
        d="M13.4526 8.45711L11.3751 1.13238C11.2896 0.821128 11.1037 0.546822 10.8463 0.352083C10.5889 0.157343 10.2744 0.0530736 9.9516 0.0554743C9.63153 0.0540745 9.31971 0.156918 9.06327 0.348457C8.80684 0.539996 8.61973 0.809815 8.53025 1.11712L6.79717 7.0641C6.79717 7.09026 6.78191 7.1186 6.72523 7.1186C6.71704 7.11982 6.70868 7.11936 6.70068 7.11725C6.69267 7.11514 6.68518 7.11143 6.67865 7.10633C6.67212 7.10123 6.6667 7.09486 6.66272 7.0876C6.65873 7.08034 6.65627 7.07234 6.65547 7.0641L4.93765 1.11712C4.8484 0.807995 4.66016 0.536707 4.40181 0.344918C4.14347 0.153129 3.82931 0.0514426 3.50758 0.0554743C3.18621 0.0543851 2.8734 0.159005 2.61734 0.353213C2.36129 0.547421 2.1762 0.82044 2.0906 1.1302L0 8.45711H1.44968L3.4291 1.5117C3.4291 1.48554 3.44654 1.45066 3.51194 1.45066C3.52112 1.44911 3.53051 1.44941 3.53956 1.45155C3.54862 1.45368 3.55716 1.45761 3.56467 1.46309C3.57219 1.46858 3.57853 1.47551 3.58333 1.48348C3.58812 1.49146 3.59128 1.50031 3.5926 1.50952L5.31697 7.45214C5.40337 7.75913 5.58817 8.02926 5.84297 8.22106C6.09778 8.41285 6.4085 8.51569 6.72741 8.51379C7.04624 8.51558 7.35689 8.41299 7.61195 8.22169C7.86702 8.03039 8.05248 7.76089 8.14004 7.45432L9.88402 1.5117C9.88507 1.5023 9.88803 1.49321 9.89271 1.48498C9.89739 1.47676 9.9037 1.46957 9.91124 1.46386C9.91879 1.45816 9.92741 1.45404 9.9366 1.45177C9.94579 1.4495 9.95534 1.44912 9.96468 1.45066C9.97401 1.44912 9.98356 1.4495 9.99275 1.45177C10.0019 1.45404 10.0106 1.45816 10.0181 1.46386C10.0257 1.46957 10.032 1.47676 10.0366 1.48498C10.0413 1.49321 10.0443 1.5023 10.0453 1.5117L12.0073 8.45711H13.4526Z"
      />
      <path
        d="M31.4331 0.055542L29.0351 7.06853C29.0315 7.08431 29.0221 7.09818 29.0088 7.10744C28.9955 7.1167 28.9793 7.1207 28.9632 7.11867C28.9473 7.12119 28.9311 7.11734 28.918 7.10795C28.9049 7.09856 28.8961 7.08439 28.8935 7.06853L26.478 0.055542H24.9935L27.5549 7.52196C27.6525 7.8129 27.8395 8.06553 28.0893 8.24369C28.3392 8.42185 28.6389 8.51642 28.9458 8.51385C29.2527 8.51676 29.5526 8.42232 29.8025 8.24411C30.0524 8.0659 30.2394 7.81308 30.3366 7.52196L32.9068 0.055542H31.4331Z"
      />
      <path
        d="M39.9809 6.15952C39.3269 6.99227 38.2239 7.23425 37.3344 7.07293C36.795 6.98392 36.2984 6.72406 35.9177 6.33161C35.537 5.93916 35.2924 5.43486 35.2199 4.89295H41.9778L41.9909 4.20844C42.0004 4.05967 42.0026 3.91053 41.9974 3.76154C41.9974 3.7441 41.9974 3.72666 41.9974 3.70922C41.9865 3.56535 41.9691 3.42147 41.9451 3.27323V3.23835C41.8624 2.72343 41.6744 2.23111 41.3927 1.79223C41.1109 1.35335 40.7417 0.97735 40.3079 0.687777V0.698676C39.6461 0.260867 38.8734 0.0204263 38.08 0.0054435C35.9981 -0.042516 33.8574 1.38319 33.7985 4.18228V4.26948C33.7985 6.43856 35.1065 8.07572 37.1099 8.44195C37.3588 8.48665 37.6113 8.50926 37.8642 8.50953C39.3357 8.50953 40.9161 7.76398 41.6116 6.15516L39.9809 6.15952ZM38.019 1.40281C38.591 1.40904 39.147 1.5926 39.6103 1.92818H39.6212C39.9173 2.15139 40.1558 2.44214 40.3167 2.77619C40.3494 2.84159 40.3777 2.91135 40.406 2.99419C40.4051 3.00289 40.4051 3.01166 40.406 3.02035C40.4322 3.09229 40.4562 3.16423 40.4758 3.23835C40.4976 3.31683 40.5172 3.39967 40.5325 3.48687H35.2526C35.5883 2.03064 36.8636 1.37883 38.0255 1.40063L38.019 1.40281Z"
      />
    </svg>
  </a>
</template>

<style lang="scss">
@import './logo';
</style>
