<script lang="ts" setup>
import { computed } from 'vue';
import { useStore } from '../../store';
import EpisodeMeta from '@components/episode-meta/Index.vue';

const store = useStore();

const episode = computed(() => {
  return store.state.episode;
});
</script>

<script lang="ts">
export default {
  name: 'EpisodeDescription',
};
</script>

<template>
  <div class="episode-description">
    <div class="episode-description__head">
      <h3 class="title">
        {{ episode.title }}
      </h3>
      <EpisodeMeta
        :episode-number="episode?.number"
        :season-number="episode?.season"
        :publish-date="episode?.publish_date"
        :duration="episode?.duration || 445344"
        :is-explicit="episode?.is_explicit"
        :is-popup-mode="true"
      />
    </div>

    <div class="episode-description__scroll-area">
      <div
        class="episode-description__content"
        v-html="episode.description"
      ></div>
    </div>
  </div>
</template>

<style lang="scss">
@import './episode-description';
</style>
