import HttpClient from './httpClient';
import { PodcastInfo, EpisodeInfo } from './types';

class ApiService extends HttpClient {
  protected podcastSlug: string;

  constructor(podcastSlug: string) {
    super(import.meta.env.VUE_API_URL);
    this.podcastSlug = podcastSlug;
  }

  public async getPodcast(): Promise<PodcastInfo> {
    const { data } = await this.httpClient.get(`website/${this.podcastSlug}`);
    return {
      title: data.podcast.title,
      feed_url: data.podcast.feed_url,
      image: data.podcast.image,
      images: data.podcast.images,
      is_explicit: data.podcast.is_explicit,
      slug: this.podcastSlug,
      author: data.podcast.author,
      platforms: data.platforms,
      socials: data.socials,
    };
  }

  public async getEpisode(episodeNumber: number): Promise<EpisodeInfo> {
    const { data } = await this.httpClient.get(
      `website/${this.podcastSlug}/episodes/${episodeNumber}`
    );
    return {
      title: data.title,
      description: data.description,
      image: data.image,
      images: data.images,
      is_explicit: data.is_explicit,
      code: data.code,
      number: data.number,
      season: data.season,
      type: data.type,
      audio: data.audio,
      duration: data.duration,
      publish_date: data.publish_date,
    };
  }
}

export default ApiService;
