<script lang="ts" setup>
import { computed, inject, ref } from 'vue';
import axios from 'axios';
import { useStore } from '../../store';
import { copyToClipboard } from '../../utils';

const storageUrl = inject('storageUrl');
const store = useStore();

const isShowCopyTooltip = ref<boolean>(false);

const playerParams = computed(() => {
  return store.state.playerParams;
});
const podcast = computed(() => {
  return store.state.podcast;
});
const episode = computed(() => {
  return store.state.episode;
});

async function download(link: string, title: string) {
  const blob = await axios.get(link, {
    responseType: 'blob',
  });

  const blobUrl = URL.createObjectURL(blob.data);

  // Create a link element
  const linkElement = document.createElement('a');

  // Set link's href to point to the Blob URL
  linkElement.href = blobUrl;
  linkElement.download = `${title}.mp3`.replaceAll(' ', '-');

  // Append link to the body
  document.body.appendChild(linkElement);

  // Dispatch click event on the link
  // This is necessary as link.click() does not work on the latest firefox
  linkElement.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    })
  );

  // Remove link from body
  document.body.removeChild(linkElement);
}

async function copy(value: string) {
  await copyToClipboard(value);
  isShowCopyTooltip.value = true;
  setTimeout(() => {
    isShowCopyTooltip.value = false;
  }, 3000);
}
</script>

<script lang="ts">
import tippy from 'tippy.js';

export default {
  name: 'EpisodeSharing',
  mounted() {
    tippy('[data-tippy-content]', {
      touch: false,
      arrow: false,
      theme: 'm-tippy',
      placement: 'bottom',
      offset: [0, 4],
    });
  },
};
</script>

<template>
  <div class="episode-sharing">
    <h3 class="episode-sharing__title">Поделись с друзьями ✌️</h3>
    <div class="episode-sharing__clipboard clipboard">
      <div class="clipboard__icon">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
          <g clip-path="url(#clip0_16346_131310)">
            <path
              d="M7.49988 10.5C7.74429 10.7495 8.03601 10.9477 8.35797 11.0829C8.67993 11.2182 9.02565 11.2879 9.37488 11.2879C9.72411 11.2879 10.0698 11.2182 10.3918 11.0829C10.7137 10.9477 11.0055 10.7495 11.2499 10.5L14.2499 7.50004C14.7472 7.00276 15.0265 6.3283 15.0265 5.62504C15.0265 4.92178 14.7472 4.24732 14.2499 3.75004C13.7526 3.25276 13.0781 2.97339 12.3749 2.97339C11.6716 2.97339 10.9972 3.25276 10.4999 3.75004L10.1249 4.12504"
              stroke-width="1.3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M10.4999 7.50004C10.2555 7.25059 9.96378 7.05242 9.64182 6.91713C9.31986 6.78184 8.97415 6.71216 8.62492 6.71216C8.27569 6.71216 7.92997 6.78184 7.60801 6.91713C7.28605 7.05242 6.99433 7.25059 6.74992 7.50004L3.74992 10.5C3.25264 10.9973 2.97327 11.6718 2.97327 12.375C2.97327 13.0783 3.25264 13.7528 3.74992 14.25C4.2472 14.7473 4.92166 15.0267 5.62492 15.0267C6.32818 15.0267 7.00264 14.7473 7.49992 14.25L7.87492 13.875"
              stroke-width="1.3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_16346_131310">
              <rect width="18" height="18" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
      <span class="clipboard__text">
        {{ `${podcast.slug}.mave.digital/ep-${episode.code}` }}
      </span>
      <svg
        ref="copyButton"
        class="clipboard__copy-button"
        role="button"
        tabindex="0"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        @click="copy(`https://${podcast.slug}.mave.digital/ep-${episode.code}`)"
        @keyup.enter="
          copy(`https://${podcast.slug}.mave.digital/ep-${episode.code}`)
        "
      >
        <g clip-path="url(#clip0_16346_131428)">
          <path
            d="M13.5 6H7.5C6.67157 6 6 6.67157 6 7.5V13.5C6 14.3284 6.67157 15 7.5 15H13.5C14.3284 15 15 14.3284 15 13.5V7.5C15 6.67157 14.3284 6 13.5 6Z"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M12 6V4.5C12 4.10218 11.842 3.72064 11.5607 3.43934C11.2794 3.15804 10.8978 3 10.5 3H4.5C4.10218 3 3.72064 3.15804 3.43934 3.43934C3.15804 3.72064 3 4.10218 3 4.5V10.5C3 10.8978 3.15804 11.2794 3.43934 11.5607C3.72064 11.842 4.10218 12 4.5 12H6"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_16346_131428">
            <rect width="18" height="18" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>

    <div class="episode-sharing__socials socials-list">
      <a
        class="socials-list__item"
        :href="`https://t.me/share/url?url=https://${podcast.slug}.mave.digital/ep-${episode.code}&text=${episode.title}`"
        target="_blank"
        rel="noopener"
        aria-label="Поделиться выпуском в телеграм"
      >
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none">
          <g clip-path="url(#clip0_16668_7422)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6.45913 11.9109C10.5304 10.1371 13.2452 8.96773 14.6036 8.40274C18.482 6.78958 19.2879 6.50935 19.8131 6.5001C19.9287 6.49806 20.187 6.5267 20.3543 6.66247C20.4956 6.77711 20.5345 6.93198 20.5531 7.04067C20.5717 7.14937 20.5948 7.39698 20.5764 7.59045C20.3663 9.79875 19.4568 15.1577 18.9942 17.631C18.7984 18.6776 18.413 19.0285 18.0398 19.0628C17.2288 19.1375 16.6129 18.5269 15.8274 18.012C14.5983 17.2062 13.9039 16.7047 12.7108 15.9184C11.3319 15.0098 12.2258 14.5104 13.0116 13.6942C13.2172 13.4806 16.7905 10.2304 16.8597 9.93555C16.8683 9.89868 16.8764 9.76122 16.7947 9.68864C16.7131 9.61606 16.5925 9.64088 16.5056 9.66062C16.3823 9.6886 14.4187 10.9865 10.6148 13.5542C10.0574 13.9369 9.55258 14.1234 9.10026 14.1136C8.60161 14.1029 7.64242 13.8317 6.92936 13.5999C6.05476 13.3156 5.35965 13.1653 5.42018 12.6825C5.45171 12.431 5.79802 12.1738 6.45913 11.9109Z"
            />
          </g>
          <defs>
            <clipPath id="clip0_16668_7422">
              <rect width="26" height="26" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </a>
      <a
        class="socials-list__item"
        :href="`https://twitter.com/intent/tweet?text=https://${podcast.slug}.mave.digital/ep-${episode.code}`"
        target="_blank"
        rel="noopener"
        aria-label="Поделиться выпуском в твиттер"
      >
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none">
          <g clip-path="url(#clip0_16668_7442)">
            <path
              d="M10.2312 19.5C16.2686 19.5 19.5708 14.4981 19.5708 10.1604C19.5708 10.0184 19.5708 9.87694 19.5612 9.73615C20.2036 9.27148 20.7581 8.69613 21.1988 8.03705C20.5997 8.30251 19.9642 8.4766 19.3135 8.5535C19.9987 8.14328 20.5116 7.49806 20.7566 6.73794C20.1123 7.12028 19.4073 7.38974 18.6722 7.53469C18.1773 7.00844 17.5228 6.65997 16.8099 6.54321C16.097 6.42644 15.3655 6.54789 14.7285 6.88876C14.0916 7.22963 13.5848 7.77092 13.2865 8.42886C12.9882 9.0868 12.915 9.82472 13.0784 10.5284C11.7733 10.463 10.4966 10.1239 9.33117 9.53302C8.16571 8.94218 7.13752 8.11285 6.31336 7.09887C5.8936 7.82149 5.76504 8.67692 5.95385 9.491C6.14265 10.3051 6.63463 11.0166 7.32961 11.4807C6.80724 11.4652 6.29626 11.3243 5.83979 11.0698C5.83979 11.0833 5.83979 11.0973 5.83979 11.1114C5.84 11.8693 6.10234 12.6037 6.58232 13.1902C7.0623 13.7767 7.73037 14.1791 8.47322 14.3291C7.98997 14.4609 7.48293 14.4802 6.99107 14.3854C7.20083 15.0377 7.60919 15.608 8.15905 16.0168C8.7089 16.4255 9.37276 16.6521 10.0578 16.665C8.89542 17.5785 7.45956 18.0744 5.98122 18.0729C5.72005 18.0724 5.45914 18.0566 5.19983 18.0255C6.70094 18.9888 8.44757 19.4998 10.2312 19.4974"
            />
          </g>
          <defs>
            <clipPath id="clip0_16668_7442">
              <rect width="26" height="26" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </a>
      <a
        class="socials-list__item"
        :href="`http://vk.com/share.php?url=https://${podcast.slug}.mave.digital/ep-${episode.code}&title=${episode.title}`"
        target="_blank"
        rel="noopener"
        aria-label="Поделиться выпуском в вк"
      >
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none">
          <g clip-path="url(#clip0_16668_7450)">
            <path
              d="M13.6771 18.1304C8.49528 18.1304 5.53966 14.5779 5.4165 8.66663H8.01216C8.09742 13.0054 10.011 14.8432 11.5267 15.2221V8.66663L13.9709 8.66663V12.4085C15.4676 12.2475 17.04 10.5423 17.5705 8.66663H20.0147C19.6073 10.9781 17.9021 12.6833 16.6896 13.3843C17.9021 13.9527 19.8443 15.44 20.5832 18.1304H17.8927C17.3148 16.3305 15.875 14.9379 13.9709 14.7484L13.9709 18.1304H13.6771Z"
            />
          </g>
          <defs>
            <clipPath id="clip0_16668_7450">
              <rect width="26" height="26" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </a>
      <a
        v-if="playerParams.download"
        class="socials-list__item download"
        :href="`${storageUrl}${episode.audio}`"
        aria-label="Скачать выпуск"
        data-tippy-content="Скачать выпуск"
        @click.prevent="
          download(`${storageUrl}${episode.audio}`, `ep-${episode.code}`)
        "
      >
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none">
          <path
            d="M12.8334 2.75V6.41667C12.8334 6.65978 12.93 6.89294 13.1019 7.06485C13.2738 7.23676 13.5069 7.33333 13.75 7.33333H17.4167"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M15.5834 19.25H6.41671C5.93048 19.25 5.46416 19.0568 5.12034 18.713C4.77653 18.3692 4.58337 17.9029 4.58337 17.4167V4.58333C4.58337 4.0971 4.77653 3.63079 5.12034 3.28697C5.46416 2.94315 5.93048 2.75 6.41671 2.75H12.8334L17.4167 7.33333V17.4167C17.4167 17.9029 17.2236 18.3692 16.8797 18.713C16.5359 19.0568 16.0696 19.25 15.5834 19.25Z"
            stroke-width="1.25"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M11 10.0834V15.5834"
            stroke-width="1.25"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M8.25 12.8334L11 15.5834L13.75 12.8334"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </a>
    </div>

    <Transition name="fade">
      <div v-if="isShowCopyTooltip" class="copy-tooltip">
        <span class="copy-tooltip__text"> Ссылка скопирована </span>
      </div>
    </Transition>
  </div>
</template>

<style lang="scss">
@import './episode-sharing';
</style>
