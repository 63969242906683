<script lang="ts" setup>
const props = defineProps({
  color: {
    type: String,
    required: true,
  },
});
</script>

<script lang="ts">
export default {
  name: 'IconPlayerPause',
};
</script>

<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M7.32143 3.12498H5.35714C4.81472 3.12498 4.375 3.56471 4.375 4.10713V15.8928C4.375 16.4353 4.81472 16.875 5.35714 16.875H7.32143C7.86385 16.875 8.30357 16.4353 8.30357 15.8928V4.10713C8.30357 3.56471 7.86385 3.12498 7.32143 3.12498Z"
      :fill="props.color"
    />
    <path
      d="M15.1791 3.12498H13.2148C12.6724 3.12498 12.2327 3.56471 12.2327 4.10713V15.8928C12.2327 16.4353 12.6724 16.875 13.2148 16.875H15.1791C15.7215 16.875 16.1612 16.4353 16.1612 15.8928V4.10713C16.1612 3.56471 15.7215 3.12498 15.1791 3.12498Z"
      :fill="props.color"
    />
  </svg>
</template>
