<script lang="ts" setup>
import { computed, inject } from 'vue';
import { useStore } from '../../store';
import { ImagesObject } from '../../types';

const props = defineProps({
  title: {
    type: String,
  },
  image: {
    type: String,
  },
  images: {
    type: Object as () => ImagesObject,
  },
  podcastSlug: {
    type: String,
  },
  episodeNumber: {
    type: Number,
  },
});

const store = useStore();
const storageUrl = inject('storageUrl');

const isDataLoaded = computed(() => {
  return store.state.isDataLoaded;
});
</script>

<script lang="ts">
export default {
  name: 'EpisodeImage',
};
</script>

<template>
  <figure class="episode-image">
    <MSkeleton
      v-if="!isDataLoaded"
      class="episode-image__image"
      type="rounded"
    />
    <a
      v-else
      :href="`https://${props.podcastSlug}.mave.digital/ep-${props.episodeNumber}?utm_source=embed&utm_medium=cover`"
      target="_blank"
      tabindex="-1"
    >
      <img
        class="episode-image__image"
        :src="`${storageUrl}${
          props.images ? props.images.image_240 : props.image
        }`"
        :alt="props.title"
      />
    </a>
  </figure>
</template>

<style lang="scss">
@import './episode-image';
</style>
