<script setup lang="ts">
import { computed } from 'vue';
import LogoLink from '../logo/Index.vue';

const props = defineProps({
  show: {
    type: Boolean,
  },
  isLogoDesktop: {
    type: Boolean,
    default: true,
  },
  isLogoMobile: {
    type: Boolean,
    default: true,
  },
  isBackdrop: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['close']);

const classes = computed(() => {
  const classes = [];
  classes.push(props.isLogoDesktop ? 'logo-desktop' : '');
  classes.push(props.isLogoMobile ? 'logo-mobile' : '');
  classes.push(props.isBackdrop ? 'backdrop' : '');

  return classes;
});
</script>

<script lang="ts">
export default {
  name: 'MPopup',
};
</script>

<template>
  <Transition name="fade">
    <div v-if="show" class="popup" :class="classes">
      <m-button-rect
        class="popup__close"
        label="Закрыть окно"
        @click="emit('close')"
      >
        <svg
          class="svg-stroke"
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
        >
          <path
            d="M10.125 1.875L1.875 10.125M1.875 1.875L10.125 10.125L1.875 1.875Z"
            stroke="var(--primary-color)"
            stroke-width="1.7"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </m-button-rect>
      <div class="popup__inner">
        <slot />
      </div>
      <div class="popup__logo">
        <LogoLink />
      </div>
    </div>
  </Transition>
</template>

<style lang="scss">
@import './popup';
</style>
