import { DateTime } from 'luxon';

export function convertIsoDate(date: string, format: string): string {
  return DateTime.fromISO(date).setLocale('ru').toFormat(format);
}

/**
 * @description Formate seconds to hh:mm:ss
 */
export function secondsToHMS(value: string | number): string {
  value = Number(value);
  const h = Math.floor(value / 3600);
  const m = Math.floor((value % 3600) / 60);
  const s = Math.floor((value % 3600) % 60);

  const hDisplay = h > 0 && h <= 9 ? `0${h}:` : h > 0 && h > 9 ? `${h}:` : '';
  const mDisplay =
    m > 0 && m <= 9 ? `0${m}:` : m > 0 && m > 9 ? `${m}:` : '00:';
  const sDisplay = s > 0 && s <= 9 ? `0${s}` : s > 0 && s > 9 ? `${s}` : '00';
  return `${hDisplay}${mDisplay}${sDisplay}`;
}

/**
 * @description Formate seconds in human readable string, ex. 1 ч 38 мин
 */
export function secondsToHumanString(value: string | number): string {
  value = Number(value);
  const h = Math.floor(value / 3600);
  const m = Math.floor((value % 3600) / 60);

  const hDisplay = h > 0 ? `${h} ч ` : '';
  const mDisplay = m === 0 ? '1 мин' : `${m} мин`;
  return `${hDisplay}${mDisplay}`;
}
