<script lang="ts" setup>
import { computed } from 'vue';
import { useStore } from '../../store';

const platformsMetaData = [
  {
    id: 1,
    link: '',
    match: /apple/,
    title: 'Apple Podcasts',
    icon: '/icons/brands/apple-podcasts.svg',
  },
  {
    id: 2,
    link: '',
    match: /google/,
    title: 'Google Podcasts',
    icon: '/icons/brands/google-podcasts.svg',
  },
  {
    id: 3,
    link: '',
    match: /yandex/,
    title: 'Яндекс.Музыка',
    icon: '/icons/brands/yandex-music.svg',
  },
  {
    id: 4,
    link: '',
    match: /castbox/,
    title: 'Castbox',
    icon: '/icons/brands/castbox.svg',
  },
  {
    id: 5,
    link: '',
    match: /pocketCasts/,
    title: 'PocketCasts',
    icon: '/icons/brands/pocket-casts.svg',
  },
  {
    id: 6,
    link: '',
    match: /podcastAddict/,
    title: 'Podcast Addict',
    icon: '/icons/brands/podcast-addict.svg',
  },
  {
    id: 7,
    link: '',
    match: /overcast/,
    title: 'Overcast',
    icon: '/icons/brands/overcast.svg',
  },
  {
    id: 8,
    link: '',
    match: /soundstream/,
    title: 'SoundStream',
    icon: '/icons/brands/soundstream.svg',
  },
  {
    id: 9,
    link: '',
    match: /vk/,
    title: 'VK',
    icon: '/icons/brands/vk.svg',
  },
  {
    id: 10,
    link: '',
    match: /spotify/,
    title: 'Spotify',
    icon: '/icons/brands/spotify.svg',
  },
  {
    id: 11,
    link: '',
    match: /deezer/,
    title: 'Deezer',
    icon: '/icons/brands/deezer.svg',
  },
  {
    id: 12,
    link: '',
    match: /youtube/,
    title: 'Youtube',
    icon: '/icons/brands/youtube.svg',
  },
  {
    id: 13,
    link: '',
    match: /soundcloud/,
    title: 'SoundCloud',
    icon: '/icons/brands/soundcloud.svg',
  },
  {
    id: 14,
    link: '',
    match: /zvuk/,
    title: 'Звук',
    icon: '/icons/brands/zvuk.svg',
  },
];

const store = useStore();

const podcast = computed(() => {
  return store.state.podcast;
});

// any type — tmp fix
const storePlatforms: any = computed(() => {
  return store.state.platforms;
});

const platforms = computed(() => {
  return platformsMetaData
    .map((item) => {
      const platform = Object.keys(storePlatforms.value).find((p) => {
        if (item.match.test(p)) {
          return storePlatforms.value;
        }
      });

      if (!platform) {
        return item;
      }

      return {
        ...item,
        link: storePlatforms.value[platform],
      };
    })
    .filter((p) => p?.link);
});
</script>

<script lang="ts">
import tippy from 'tippy.js';

export default {
  name: 'SubscribePopup',
  mounted() {
    tippy('[data-tippy-content]', {
      touch: false,
      arrow: false,
      theme: 'm-tippy',
      placement: 'bottom',
      offset: [0, 4],
    });
  },
};
</script>

<template>
  <div class="subscribe">
    <h3 class="subscribe__title">Подпишись, где удобно ❤️️</h3>
    <div class="subscribe__scrollable-area">
      <ul class="subscribe__list platforms-list">
        <li
          v-for="(platform, index) in platforms"
          :key="index"
          class="platforms-list__item"
        >
          <a
            class="platforms-list__link"
            :href="platform.link"
            target="_blank"
            rel="noopener"
            :aria-label="platform.title"
            :data-tippy-content="platform.title"
          >
            <img :src="platform.icon" role="presentation" />
          </a>
        </li>
        <li v-if="podcast.feed_url" class="platforms-list__item">
          <a
            class="platforms-list__link"
            :href="podcast.feed_url"
            target="_blank"
            rel="noopener"
            data-tippy-content="RSS"
          >
            <img src="/icons/rss.svg" />
          </a>
        </li>
      </ul>
      <a
        class="subscribe__website-link"
        :href="`https://${podcast.slug}.mave.digital`"
        target="_blank"
      >
        Сайт подкаста
      </a>
    </div>
  </div>
</template>

<style lang="scss">
@import './subscribe';
</style>
