<script lang="ts" setup>
const props = defineProps({
  color: {
    type: String,
    required: true,
  },
});
</script>

<script lang="ts">
export default {
  name: 'IconPlayerMute',
};
</script>

<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M10 5.33333C10.414 5.64382 10.75 6.04644 10.9814 6.50929C11.2128 6.97214 11.3333 7.48251 11.3333 8C11.3333 8.51748 11.2128 9.02786 10.9814 9.49071C10.75 9.95356 10.414 10.3562 10 10.6667"
      :stroke="props.color"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.8 3.33333C12.496 3.89577 13.0574 4.60675 13.443 5.41421C13.8287 6.22168 14.0288 7.10517 14.0288 8C14.0288 8.89483 13.8287 9.77832 13.443 10.5858C13.0574 11.3932 12.496 12.1042 11.8 12.6667"
      :stroke="props.color"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4 10H2.66667C2.48986 10 2.32029 9.92976 2.19526 9.80474C2.07024 9.67971 2 9.51014 2 9.33333V6.66667C2 6.48986 2.07024 6.32029 2.19526 6.19526C2.32029 6.07024 2.48986 6 2.66667 6H4L6.33333 3C6.3916 2.88682 6.48843 2.79821 6.60633 2.75019C6.72422 2.70217 6.8554 2.69791 6.97617 2.73816C7.09693 2.77842 7.19932 2.86054 7.26482 2.96969C7.33032 3.07885 7.35463 3.20783 7.33333 3.33333V12.6667C7.35463 12.7922 7.33032 12.9212 7.26482 13.0303C7.19932 13.1395 7.09693 13.2216 6.97617 13.2618C6.8554 13.3021 6.72422 13.2978 6.60633 13.2498C6.48843 13.2018 6.3916 13.1132 6.33333 13L4 10Z"
      :stroke="props.color"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
