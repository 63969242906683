import { InjectionKey } from 'vue';
import { createStore, useStore as baseUseStore, Store } from 'vuex';
import {
  UrlParams,
  PodcastInfo,
  PodcastPlatforms,
  EpisodeInfo,
  PodcastSocials,
} from './types';

// define your typings for the store state
interface State {
  isDataLoaded: boolean;
  isSubscribeOpen: boolean;
  isDescriptionOpen: boolean;
  isSharingOpen: boolean;
  isMenuOpen: boolean;
  playerParams: UrlParams;
  podcast: PodcastInfo;
  episode: EpisodeInfo;
  platforms: PodcastPlatforms;
  socials: PodcastSocials;
}

// define injection key
export const key: InjectionKey<Store<State>> = Symbol();

export const store = createStore<State>({
  state: {
    isDataLoaded: false,
    isSubscribeOpen: false,
    isDescriptionOpen: false,
    isMenuOpen: false,
    isSharingOpen: false,
    playerParams: <UrlParams>{},
    podcast: <PodcastInfo>{},
    episode: <EpisodeInfo>{},
    platforms: <PodcastPlatforms>{},
    socials: <PodcastSocials>{},
  },
  mutations: {
    SET_IS_DATA_LOADED(state, value) {
      state.isDataLoaded = value;
    },
    SET_IS_SUBSCRIBE_OPEN(state, value) {
      state.isSubscribeOpen = value;
    },
    SET_IS_DESCRIPTION_OPEN(state, value) {
      state.isDescriptionOpen = value;
    },
    SET_IS_SHARING_OPEN(state, value) {
      state.isSharingOpen = value;
    },
    SET_IS_MENU_OPEN(state, value) {
      state.isMenuOpen = value;
    },
    SET_PARAMS(state, value) {
      state.playerParams = value;
    },
    SET_PODCAST(state, value) {
      state.podcast = value;
    },
    SET_EPISODE(state, value) {
      state.episode = value;
    },
    SET_PLATFORMS(state, value) {
      if (value.apple_id) {
        delete value.apple_id;
      }
      state.platforms = value;
    },
    SET_SOCIALS(state, value) {
      state.socials.telegram = value.telegram;
      state.socials.twitter = value.twitter;
      state.socials.vk = value.vk;
    },
  },
  actions: {
    setIsDataLoaded({ commit }, value) {
      commit('SET_IS_DATA_LOADED', value);
    },
    setIsSubscribeOpen({ commit }, value) {
      commit('SET_IS_SUBSCRIBE_OPEN', value);
    },
    setIsDescriptionOpen({ commit }, value) {
      commit('SET_IS_DESCRIPTION_OPEN', value);
    },
    setIsSharingOpen({ commit }, value) {
      commit('SET_IS_SHARING_OPEN', value);
    },
    setIsMenuOpen({ commit }, value) {
      commit('SET_IS_MENU_OPEN', value);
    },
    setParams({ commit }, value) {
      commit('SET_PARAMS', value);
    },
    setPodcastData({ commit }, value) {
      commit('SET_PODCAST', value);
      commit('SET_PLATFORMS', value.platforms);
      commit('SET_SOCIALS', value.socials);
    },
    setEpisodeData({ commit }, value) {
      commit('SET_EPISODE', value);
    },
  },
});

export function useStore() {
  return baseUseStore(key);
}
