import axios, { AxiosInstance } from 'axios';

export default abstract class HttpClient {
  protected readonly httpClient: AxiosInstance;

  constructor(baseUrl: string) {
    this.httpClient = axios.create({
      baseURL: baseUrl,
    });
    // this._initializeResponseInterceptor();
  }

  // private _initializeResponseInterceptor = () => {
  //   this.httpClient.interceptors.response.use(this._handleError);
  // };

  // private _handleResponse = ({ data }: AxiosResponse) => data;

  // protected _handleError = (error: any) => Promise.reject(error);
}
