<script setup lang="ts">
import { computed, ref } from 'vue';
import { useStore } from '../store';
import ApiService from '../api';
import EpisodeImage from '@components/episode-image/Index.vue';
import EpisodeMeta from '@components/episode-meta/Index.vue';
import EpisodeTitle from '@components/episode-title/Index.vue';
import EpisodeToolbar from '@components/episode-toolbar/Index.vue';
import Subscribe from '@components/subscribe/Index.vue';
import EpisodeDescription from '@components/episode-description/Index.vue';
import EpisodeSharing from '@components/episode-sharing/Index.vue';
import MenuPopup from '@components/menu/Index.vue';
import MPlayer from '@components/player/Index.vue';
import MPopup from '@components/popup/Index.vue';
import LogoLink from '../components/logo/Index.vue';

const props = defineProps({
  params: {
    type: Object,
    required: true,
  },
});

const store = useStore();
store.dispatch('setParams', props.params);

const podcastData = ref();
const episodeData = ref();

const isSubscribeOpen = computed(() => {
  return store.state.isSubscribeOpen;
});
const isDescriptionOpen = computed(() => {
  return store.state.isDescriptionOpen;
});
const isSharingOpen = computed(() => {
  return store.state.isSharingOpen;
});
const isMenuOpen = computed(() => {
  return store.state.isMenuOpen;
});

if (props.params.podcast) {
  const apiService = new ApiService(props.params.podcast);
  Promise.all([
    getPodcastData(apiService),
    getEpisodeData(apiService, props.params.episode),
  ])
    .then((result) => {
      podcastData.value = result[0];
      episodeData.value = result[1];
      store.dispatch('setPodcastData', result[0]);
      store.dispatch('setEpisodeData', result[1]);
    })
    .catch((e) => {
      console.log(e);
    })
    .finally(() => {
      store.dispatch('setIsDataLoaded', true);
    });
}

async function getPodcastData(apiService: ApiService) {
  return apiService.getPodcast();
}

async function getEpisodeData(apiService: ApiService, episodeNumber: number) {
  return apiService.getEpisode(episodeNumber);
}

function closeSubscribePopup() {
  store.dispatch('setIsSubscribeOpen', false);
}

function closeDescriptionPopup() {
  store.dispatch('setIsDescriptionOpen', false);
}

function closeSharingPopup() {
  store.dispatch('setIsSharingOpen', false);
}

function closeMenuPopup() {
  store.dispatch('setIsMenuOpen', false);
}
</script>

<script lang="ts">
export default {
  name: 'PlayerWrapper',
};
</script>

<template>
  <div class="player">
    <div class="player-inner">
      <div class="player-top">
        <EpisodeImage
          class="player-image"
          :title="episodeData?.title"
          :image="episodeData?.image"
          :images="episodeData?.images"
          :podcast-slug="podcastData?.slug"
          :episode-number="episodeData?.code"
        />

        <div class="player-content">
          <div class="player-content__metas">
            <EpisodeMeta
              :episode-number="episodeData?.number"
              :season-number="episodeData?.season"
              :publish-date="episodeData?.publish_date"
              :duration="episodeData?.duration"
              :is-explicit="episodeData?.is_explicit"
            />
          </div>

          <EpisodeTitle
            :podcast-title="podcastData?.title"
            :podcast-slug="podcastData?.slug"
            :episode-title="episodeData?.title"
            :episode-number="episodeData?.code"
          />

          <EpisodeToolbar class="player-content__toolbar" />
        </div>
      </div>

      <div class="player-bottom">
        <MPlayer />
      </div>
    </div>

    <LogoLink position="top-right" />

    <MPopup
      :show="isSubscribeOpen"
      :is-logo-mobile="false"
      @close="closeSubscribePopup"
    >
      <Subscribe />
    </MPopup>

    <MPopup
      :show="isDescriptionOpen"
      :is-logo-mobile="false"
      :is-logo-desktop="false"
      @close="closeDescriptionPopup"
    >
      <EpisodeDescription />
    </MPopup>

    <MPopup :show="isSharingOpen" @close="closeSharingPopup">
      <EpisodeSharing />
    </MPopup>

    <MPopup
      :show="isMenuOpen"
      :is-logo-mobile="false"
      :is-logo-desktop="false"
      :is-backdrop="true"
      @close="closeMenuPopup"
    >
      <MenuPopup />
    </MPopup>
  </div>
</template>

<style lang="scss">
@import '@styles/components/player';
</style>
