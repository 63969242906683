<script lang="ts" setup>
import { computed } from 'vue';
import { useStore } from '../../store';
import { convertIsoDate, secondsToHumanString } from '../../utils';

const props = defineProps({
  seasonNumber: {
    type: Number || null,
  },
  episodeNumber: {
    type: Number || null,
  },
  publishDate: {
    type: String,
  },
  duration: {
    type: Number,
  },
  isExplicit: {
    type: Boolean,
  },
  isPopupMode: {
    type: Boolean,
    default: false,
  },
});

const store = useStore();

const playerParams = computed(() => {
  return store.state.playerParams;
});

const isDataLoaded = computed(() => {
  return store.state.isDataLoaded;
});

const getEpisodeNumberAndSeason = computed(() => {
  if (!props.episodeNumber && !props.seasonNumber) {
    return '';
  } else if (!props.episodeNumber && props.seasonNumber) {
    return `${props.seasonNumber} сезон`;
  } else if (props.episodeNumber && !props.seasonNumber) {
    return `${props.episodeNumber} выпуск`;
  }

  return `${props.episodeNumber ? `${props.episodeNumber} выпуск ` : ''}${
    props.seasonNumber ? `${props.seasonNumber} сезона` : ''
  }`;
});

const getPublishDate = computed(() => {
  if (!props.publishDate) return '';
  return convertIsoDate(props.publishDate, 'd MMM yyyy');
});

const getDuration = computed(() => {
  if (!props.duration) return '';
  return secondsToHumanString(props.duration);
});
</script>

<script lang="ts">
export default {
  name: 'EpisodeMeta',
};
</script>

<template>
  <div class="episode-meta" :class="isPopupMode ? 'popup-mode' : ''">
    <MSkeleton v-if="!isDataLoaded" />
    <div v-else class="episode-meta__wrapper">
      <span
        v-if="props.episodeNumber || props.seasonNumber"
        class="episode-meta__item"
      >
        {{ getEpisodeNumberAndSeason }}
      </span>
      <span v-if="playerParams.date" class="episode-meta__item">
        {{ getPublishDate }}
      </span>
      <span class="episode-meta__item">
        {{ getDuration }}
      </span>
      <svg
        v-if="props.isExplicit"
        class="episode-meta__item icon"
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
      >
        <g opacity="0.75">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8.75 0.875H5.25C2.83375 0.875 0.875 2.83375 0.875 5.25V8.75C0.875 11.1662 2.83375 13.125 5.25 13.125H8.75C11.1662 13.125 13.125 11.1662 13.125 8.75V5.25C13.125 2.83375 11.1662 0.875 8.75 0.875ZM5.25 0C2.35051 0 0 2.35051 0 5.25V8.75C0 11.6495 2.35051 14 5.25 14H8.75C11.6495 14 14 11.6495 14 8.75V5.25C14 2.35051 11.6495 0 8.75 0H5.25Z"
            fill="#828282"
          />
          <path
            d="M5.25 9.27498V4.37498H8.64499V4.99098H6.03399V6.48898H8.092V7.07698H6.03399V8.65898H8.743V9.27498H5.25Z"
            fill="#828282"
          />
        </g>
      </svg>
    </div>
  </div>
</template>

<style lang="scss">
@import './episode-meta';
</style>
