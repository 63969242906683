import { createApp } from 'vue';
// import * as Sentry from '@sentry/vue';
import { store, key } from './store';
import App from './App.vue';
import MSkeleton from '@components/skeleton/Index.vue';
import MButtonRect from '@components/button-rect/Index.vue';

import 'tippy.js/dist/tippy.css';
import './styles/main.scss';

const app = createApp(App);

// if (import.meta.env.PROD) {
//   Sentry.init({
//     app,
//     dsn: import.meta.env.VUE_SENTRY_DSN,
//     environment: 'production',
//     tracesSampleRate: 1,
//   });
// }

app.use(store, key);
app.component('MSkeleton', MSkeleton);
app.component('MButtonRect', MButtonRect);
app.mount('#app');
